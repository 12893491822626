@import "src/variables";
@import "src/mixins";

/* You can add global styles to this file, and also import other style files */

html {
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  background: #F5F7FA;
  font-family: $font-family;
  font-weight: 600;
  color: $gray;

  @include desktop {
    background: $white;
  }
}

html, body {
  margin: 0;
  padding: 0;
}

.btn {
  border: none;
  border-radius: 20px;
  padding: 16px 32px;
  color: $white;
  font-size: 14px;
  text-align: center;
  background: $blue;
  cursor: pointer;
  transition: background-color .3s;
}

.btn.secondary {
  background: transparent;
  color: $blue;
  border: 1px solid $blue;
}

.btn[disabled], .btn.disabled {
  background: $light-gray;
  pointer-events: none;
}

.btn.wide {
  padding-left: 64px;
  padding-right: 64px;
}

.btn.small {
  padding: 10px 16px;
}

a.btn {
  text-decoration: none;
}

a {
  color: $blue;
}

a.disabled:not(.btn) {
  color: $light-gray;
  pointer-events: none;
}

.footer-links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: $font-family;

  a {
    color: $gray;
    text-decoration: none;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

label {
  color: $gray;
  font-size: 14px;
  font-weight: 500;
}

input {
  border: 1px solid $blue;
  border-radius: 34.5px;
  height: 40px;
  padding: 0 18px;
  outline: none;
}

.form-input {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    margin-left: 18px;
    margin-bottom: 9px;
  }

  .form-input-info,
  .form-input-error {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    position: absolute;
    bottom: -16px;
    left: 18px;
    transition: opacity .3s;
  }

  .form-input-error {
    color: $red;
  }
}

form .form-input {
  margin-top: 30px;
}

form .small-text {
  font-weight: 500;
  font-size: 12px;
}

form .smaller-text {
  font-weight: 500;
  font-size: 10px;
}

.form-title {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: -10px;
}

.form-input + .form-title {
  margin-top: 45px;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 32px 25px;
  box-sizing: border-box;

  .title {
    color: $gray;
    font-size: 20px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    margin-left: 17px;

    &.long {
      margin-left: 0px;
    }
  }

  .body {
    margin: 50px 0;
    font-weight: 500;
    font-size: 14px;
  }
}

.card.white {
  background: $white;
}

.card.info {
  background: $navy-blue-3;
  color: white;

  .title {
    color: white;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }

  .body {
    font-weight: 300;
    margin: 18px 0 0;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $blue;
}

mat-select {
  flex: 0 0 60px;
  width: 75px;
  border: 1px solid $blue;
  border-radius: 20px;
  padding: 6px;
  box-sizing: border-box;
  margin-right: 5px;
}

.full-page-title {
  background: $navy-blue-3;
  width: 100%;
  height: 48px;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  max-width: $content-width-lg;
}

.list {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0;
  .list-title {
    margin-bottom: 12px;
  }
  ul {
    margin: 0;
    padding-left: 15px;

    li:not(:first-child) {
      margin-top: 5px;
    }
  }
}

.premium-badge {
  background: linear-gradient(239.47deg, #0090FF 0%, #7C00FF 100%);
  height: 35px;
  border-radius: 5px;
  color: $white;
  padding: 10px 16px;
  font-weight: 400;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.4);
  box-sizing: border-box;
}

.nav-back-btn {
  font-weight: 500;
  color: #444;
  font-size: 12px;
}
