@import "src/variables";

@mixin card {
  margin: 0 10px;
  border-radius: 24px;
  padding: 35px 24px;

  .header {
    font-size: 20px;
  }
}

// TODO: consider refactoring this into component
@mixin checkbox {
  mat-checkbox {
    font-family: $font-family;
    display: flex;
    font-size: 13px;

    ::ng-deep .mat-checkbox-layout {
      white-space: normal;

      .mat-checkbox-label {
        line-height: 18px;
      }
    }

    ::ng-deep {
      .mat-checkbox-frame {
        border: $blue-border;
        border-radius: 4px;
      }
      .mat-checkbox-inner-container {
        margin-right: 14px;
        margin-top: 0;
      }
    }
  }

  ::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $blue;
  }
}

// layout is mobile-first so this is only for special cases
@mixin mobile {
  @media (max-width: 959px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 960px) {
    @content;
  }
}
