$light-blue: #268FE0;
$lighter-blue: #EDF7FF;
$blue-3: #E0E8EE;
$blue-4: #E0E8EE;
$blue: #0090FF;
$navy-blue: #465763;
$navy-blue-2: #356E99;
$navy-blue-3: #5E7587;
$white: #fff;
$dark-white: #eee;
$font-family: 'Open Sans', sans-serif;
$blue-border: 1px solid $blue;
$content-width-lg: 960px;
$red: #FF0000;
$red-price: #C30000;
$red-price-light: rgba(195,0,0,0.15);
$light-red: #F0E6E6;

$gray: #444;
$light-gray: #7e7e7e;
$lighter-gray: #979797;
